<template>
<div class="date_zong">
  <div class="date_year">
    <div class="date_year_con">
      <p class="left_year" @click="addMonth(-1)"></p>{{KQDays.month}}月<p class="right_year" @click="addMonth(1)"></p>
    </div>
    <div class="date_year_info">
      <p><span style="background-color: #2DC66E;"></span>正常</p>
      <p><span style="background-color: #E74C3C;"></span>无提成</p>
      <p><span style="background-color: #4297FF;"></span>今天</p>
    </div>
  </div>
  <div class="date_count">
    <ul>
      <li>{{KQDays.month}}月共计：{{KQDays.allDays}}天</li>
      <li>提成天数：{{KQDays.zong}}天</li>
      <li style="color:#2DC66E;">共计提成：<strong >{{KQDays.count}}</strong></li>
    </ul>
  </div>
  <div class="date_mth">
    <div class="date_day_title">一</div>
    <div class="date_day_title">二</div>
    <div class="date_day_title">三</div>
    <div class="date_day_title">四</div>
    <div class="date_day_title">五</div>
    <div class="date_day_title" style="color:#E74C3C">六</div>
    <div class="date_day_title" style="color:#E74C3C">日</div>
    <div :class="'date_day '+item.state" v-for="(item,i) in Caldata" :key="i">
      <div class="date_number" v-if="item.dateNum">{{item.dateNum}}</div>
      <div class="date_numTc" v-if="item.dayTicheng">提成：{{item.dayTicheng}}</div>
    </div>
    <div class="clear"></div>
  </div>
  <div class="clear"></div>
</div>
</template>
<script>
import moment from 'moment'
import axios from '@/http/axios'
export default {
  name: 'Calendar',
  props: {
    userId:Number,
    itemleixing:Number,
    fenpeimendianId:Number
  },
  data () {
    return {
      Caldata: [],
      form:{
        startDate:moment().startOf("M").format("YYYY-MM-DD"),
        endDate:moment().endOf("M").format("YYYY-MM-DD"),
      },
      nowDate:moment(),
      month:0,
      KQDays:{
        month:0,
        allDays:0,
        zong:0,
        count:0,
      }
    }
  },
  watch:{
    fenpeimendianId(){
      this.getData()
    },
    itemleixing(){
      this.getData()
    },
    userId(){
      this.getData()
    },
  },
  computed:{},
  created () {
    this.addDate(this.nowDate)
  },
  methods: {
    addMonth(num){
      let n=this.month+num
      if(n>=-6&&n<=0){
        this.month+=num
        this.nowDate=this.nowDate.add(num,"M")
        this.KQDays.month=this.nowDate.month()+1
        this.KQDays.allDays=this.nowDate.daysInMonth()
        this.form.startDate=this.nowDate.startOf("M").format("YYYY-MM-DD")
        this.form.endDate=this.nowDate.endOf("M").format("YYYY-MM-DD")
        this.addDate(this.nowDate)
      }
    },
    countKQDate(arr){
      if(arr.length){
        this.KQDays.zong=0
        this.KQDays.count=0
        this.Caldata.forEach(e=>{
          if(e.dateNum){
            let record=arr.find(a=>moment(a.date).date()==e.dateNum)
            if(record){
              this.KQDays.count+=record.Count
              this.KQDays.zong+=1
              e.dayTicheng=record.Count
              e.state="succ"
            }else{
              e.dayTicheng=0
              e.state="pass"
            }
            if(e.dateNum==moment().date()){
              e.state="dang"
            }
          }
        })
        this.KQDays.count=this.KQDays.count.toFixed(2)
      }else{
        this.Caldata.forEach(e=>{
          e.dayTicheng=0
          e.state="pass"
          if(e.dateNum==moment().date()){
            e.state="dang"
          }
        })
        this.KQDays.zong=0
        this.KQDays.count=0
      }
    },
    getData(){
      if(this.fenpeimendianId&&this.userId){
        axios.post('/dataCount/tichengCount',{fenpeimendianId:this.fenpeimendianId,userId:this.userId,itemleixing:this.itemleixing,...this.form}).then((res) => {
          if(res.code===5000){
            this.countKQDate(res.data)
          }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
        })
      }else{
        this.$Mesg({content:"请选择人员！",errOrSuc: 'war'})
      }
    },
    addDate (date) {
      this.Caldata=[]
      let d=moment(date)
      let days=d.daysInMonth()
      this.KQDays.month=d.month()+1
      this.KQDays.allDays=d.daysInMonth()
      let startDay=d.startOf("M").day()==0?6:d.startOf("M").day()-1
      for(let a=0;a<startDay;a++){
        this.Caldata.push({dateNum:null})
      }
      for(let i=1;i<=days;i++){
        this.Caldata.push({
          dateNum:i,
          state:'pass',
          dayTicheng:0
        })
      }
      this.getData()
    }
  }
}
</script>
