<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>所属门店：</p>
          <select @change="getEmpList" v-model="form.fenpeimendianId">
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>提成类型：</p>
          <select @change="getData(1)" v-model="form.itemleixing">
            <option :value="0">全部</option>
            <option :value="1">商品</option>
            <option :value="2">项目</option>
            <option :value="3">充值</option>
          </select>
        </li>
        <li>
          <p>可能异常记录：</p>
          <select @change="getData(1)" v-model="form.isOrigin">
            <option :value="0">全部</option>
            <option :value="1">可能异常</option>
          </select>
        </li>
        <li>
          <p>员工：</p>
          <select @change="getData(1)" v-model="form.userId">
            <option :value="null">全部</option>
            <option :value="item.id" v-for="item in EmpList" :key="item.id">{{item.name}}</option>
          </select>
        </li>
        <li v-if="!CalendarShow">
          <p>提成时间：</p>
          <select v-model="form.Sdate" @change="addDate">
            <option v-for="item in dateList" :key="item.id" :value="item.id">{{item.name}}</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <li>
          <button type="button" v-if="tableShow==false" @click="restPH">返回排行页</button>
        </li>
        <div class="list_b_info"><i class="icon-alert-circle"></i>背景色为<b></b>的记录，表示<strong>可能</strong>存在异常提成。</div>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list" v-if="!CalendarShow">
      <div class="list_d">
        <table v-if="tableShow">
          <thead>
            <tr>
              <td style="text-align:center">排名</td>
              <td>员工</td>
              <td style="text-align:center">提成</td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">项目指定次数</td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">项目总次数</td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">指定率</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in dataList" :key="item.id" :class="item.isOrigin>0?'yichang':''">
              <td style="text-align:center">{{i+1}}</td>
              <td>{{item.xingming}}</td>
              <td style="text-align:center"><strong><span class="err">{{resetTC(item.tc)}}</span></strong></td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">{{item.zhidingNum}}</td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">{{item.zhidingNum+item.feizhidingNum}}</td>
              <td v-if="form.itemleixing==0||form.itemleixing==2" style="text-align:center">{{item.zhidinglv}}%</td>
              <td>
                <div class="buttonGroup">
                  <button type="button" class="suc" @click="setInfoData(item.userId)">详情</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <colgroup>
            <col>
            <col width="90">
          </colgroup>
          <thead>
            <tr>
              <td>员工</td>
              <td style="text-align:center">提成日期</td>
              <td style="text-align:center">提成类型</td>
              <td>项目名称</td>
              <td>项目角色</td>
              <td style="text-align:center">原价</td>
              <td style="text-align:center">实价</td>
              <td style="text-align:center">次卡实价</td>
              <td style="text-align:center">数量</td>
              <td style="text-align:center">总价</td>
              <td style="text-align:center">实收</td>
              <td style="text-align:center">提成方案</td>
              <td style="text-align:center">指定</td>
              <td style="text-align:center">百分比(%)</td>
              <td style="text-align:center">固定(￥)</td>
              <td style="text-align:center">消费次卡数</td>
              <td style="text-align:center">提成</td>
              <td style="text-align:center">状态</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id" :class="item.isOrigin>0?'yichang':''">
              <td>{{item.xingming}}</td>
              <td style="text-align:center">{{dataFormat(item.tichengriqi)}}</td>
              <td style="text-align:center">{{returnLB(item.itemleixing)}}</td>
              <td>{{!item.itemName&&item.itemleixing==3?'自由充值':item.itemName}}</td>
              <td>{{item.title}}</td>
              <td style="text-align:center">{{item.yuanjia}}</td>
              <td style="text-align:center">{{item.shijia/item.num}}</td>
              <td style="text-align:center">{{item.cikaticheng}}</td>
              <td style="text-align:center">{{item.num}}</td>
              <td style="text-align:center">{{item.shijia}}</td>
              <td style="text-align:center">{{item.shishou}}</td>
              <td style="text-align:center">{{item.percentagePlan==1?'自由分配':'限定总额'}}</td>
              <td style="text-align:center"><p v-if="item.itemleixing==2">{{item.zhiding==1?'是':'否'}}</p></td>
              <td style="text-align:center">{{item.tichengbaifenbi}}</td>
              <td style="text-align:center">{{item.tichengguding}}</td>
              <td style="text-align:center">{{item.xiaofeicishu}}</td>
              <td style="text-align:center"><strong><span class="err">{{item.ticheng}}</span></strong></td>
              <td style="text-align:center"><span :class="returnStateClass(item.state)">{{returnState(item.state)}}</span></td>
              <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="lookAt(item)" >查看</button>
              </div>
            </td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
    <Calendar v-if="CalendarShow" :userId="form.userId" :itemleixing="form.itemleixing" :fenpeimendianId="form.fenpeimendianId"></Calendar>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" @click="tichengCount" v-pow:4401>{{CalendarShow?"返回":"提成统计"}}</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData" v-if="!CalendarShow&&!tableShow"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
import Calendar from './Calendar.vue'
import { mapMutations } from "vuex"
export default {
  name: 'OperationRecord',
  data() {
    return {
      dateList:[
        {
          id:1,
          name:'今日',
          startDate:moment().format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:2,
          name:'昨日',
          startDate:moment().add(-1,"d").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"d").format("YYYY-MM-DD"),
        },
        {
          id:3,
          name:'本周',
          startDate:moment().startOf("W").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:4,
          name:'上周',
          startDate:moment().add(-1,"W").startOf("W").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"W").endOf("W").format("YYYY-MM-DD"),
        },
        {
          id:5,
          name:'本月',
          startDate:moment().startOf("M").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:6,
          name:'上月',
          startDate:moment().add(-1,"M").startOf("M").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"M").endOf("M").format("YYYY-MM-DD"),
        },

      ],
      tableShow:true,
      CalendarShow:false,
      dataList:[],
      storeList:[],
      EmpList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        itemleixing:0,
        Sdate:1,
        startDate:moment().format("YYYY-MM-DD"),
        endDate:null,
        userId:null,
        isOrigin:0,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
     this.getStore()
  },
  computed:{
    powShow(){
      return (pow)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0||(this.$store.state.User.pow&&this.$store.state.User.pow.indexOf(pow)>=0)){
            return true
          }else return false
        }else return false
      }
    },
  },
  components: {Calendar},
  mounted() {},
  methods: {
    ...mapMutations(['addNav']),
    lookAt(item){
      if(item.itemleixing==3){
        if(this.powShow(3400)){
          this.addNav({name:'充值记录',path:'/rechargeRecord'})
          this.$router.push({path:"/rechargeRecord",query:{id:item.jiluId}})
        }else this.$Mesg({content: '权限不足，无法查看充值记录！',errOrSuc: 'err'})
      }else{
        if(this.powShow(3500)){
          this.addNav({name:'消费记录',path:'/consumptionRecords'})
          this.$router.push({path:"/consumptionRecords",query:{id:item.jiluId}})
        }else this.$Mesg({content: '权限不足，无法查看消费记录！',errOrSuc: 'err'})
      }
    },
    restPH(){
      this.tableShow=true
      this.form.userId=null
      this.CalendarShow=false
      this.getData()
    },
    setInfoData(userId){
      this.tableShow=false
      this.form.userId=userId
      this.getData(1)
    },
    resetTC(tc){
      if(tc) return tc.toFixed(2)
      else return '0.00'
    },
    addDate(){
      let d=this.dateList.find(e=>e.id==this.form.Sdate)
      this.form.startDate=d.startDate
      this.form.endDate=d.endDate
      this.getData(1)
      
    },
    tichengCount(){
      this.CalendarShow=!this.CalendarShow
      this.getData()
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnStateClass(lb){
      if(lb==1){
        return 'suc'
      }
      else{
        return 'err'
      }
    },
    returnState(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '商品'
      }
      else if(lb==2){
        return '项目'
      }
      else if(lb==3){
        return '充值'
      }
    },
    getData(index){
      if(this.CalendarShow) return
      if(this.tableShow){
        axios.post('/record/tichengJL',this.form).then((res) => {
          if(res.code===5000){
            if(this.form.itemleixing==0||this.form.itemleixing==2){
              this.dataList=res.data.map(e=>{
                let emp =this.EmpList.find(a=>a.id==e.userId)
                e.xingming=emp?e.xingming:e.xingming+'（已删）'
                let arr=res.count.filter(a=>a.userId==e.userId)
                e.zhidingNum=0
                e.feizhidingNum=0
                arr.forEach(b => {
                  if(b.zhiding==1) e.zhidingNum=b.count
                  else if (b.zhiding==0) e.feizhidingNum=b.count
                });
                if(e.zhidingNum+e.feizhidingNum==0) e.zhidinglv="0.00"
                else e.zhidinglv=((e.zhidingNum/(e.zhidingNum+e.feizhidingNum))*100).toFixed(2)
                return e
              })
            }else this.dataList=res.data.map(e=>{
              let emp =this.EmpList.find(a=>a.id==e.userId)
              e.xingming=emp?e.xingming:e.xingming+'（已删）'
              return e
            })
          }else this.$Mesg({content: '获取数据失败！',errOrSuc: 'err'})
        })
      }else{
        if(index) this.pageData.pageIndex=1
        axios.post('/record/tichengInfo',{...this.form,...this.pageData}).then((res) => {
          if(res.code===5000){
            this.dataList=res.data
            this.pageData.total=res.total
          }else this.$Mesg({content: '获取数据失败！',errOrSuc: 'err'})
        })
      }
      
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.getEmpList()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data.map(e=>{
            return{
              id:e.id,
              name:e.xingming,
            }
          })
          this.form.userId=null
          this.getData(1)
        }else{this.$Mesg({content: res.msg,errOrSuc: 'err'})}
      })
    },
    
  },
}
</script>
